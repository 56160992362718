/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* Provide sufficient contrast against white background */

body {
    height: 100vh;
    font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
}

.alert-auth {
    background-color: rgb(202, 201, 201);
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.box img {
    width: 100px;
    height: 100px;
    vertical-align: middle;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: rgb(65, 112, 241) !important;
}

.stylelbl {
    background-color: #535c6b;
    color: #f0f3f7;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    margin-right: 1px;
    margin-bottom: 0;
    font-size: 14px;
    cursor: pointer;
}

.stylelbl-disable {
    background-color: #A6A9AF;
    color: #7B7D82;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    margin-right: 1px;
    margin-bottom: 0;
    font-size: 14px;
}

.mat-error {
    margin-top: -15px;
}

.etiqueta-a {
    color: white;
}

.etiqueta-a:hover {
    color: white !important;
    text-decoration: none;
}


/* Login Classes */

.login-row-middle {
    margin-top: 10%
}

.login-row-bottom {
    margin-top: 11.8%;
    background-color: white;
    height: 5% !important;
}

.login-logo {
    width: 100%;
    height: 100%
}

.mat-sidenav-container-logged {
    height: -webkit-fill-available;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.img-usuario {
    border-radius: 50%;
    height: 100%;
}

mat-divider.style-fix {
    height: 36px;
    margin-left: 8px;
    margin-right: 8px;
}


/* Table */

.alternate-row-color {
    background-color: #ececec;
    /*font-weight: bold;*/
}

.btn-menu {
    line-height: 30px !important;
    height: auto !important;
}

.mat-tr {
    height: 46px !important;
}

.mat-toolbar-opc {
    background-color: rgba(220, 224, 231);
    position: fixed;
    margin-left: -20px;
    bottom: 0;
    z-index: 1030;
    min-height: 46px !important;
    width: 101% !important;
}

.mat-dl {
    height: 46px !important;
}

.mat-toolbar-dialog {
    background-color: rgba(220, 224, 231);
    position: relative;
    margin-left: -24px;
    margin-bottom: -24px;
    bottom: 0;
    z-index: 1030;
    min-height: 46px !important;
    width: calc(100% + 48px) !important;
    overflow-y: hidden;
}

.mat-toolbar-dddw {
    background-color: rgba(220, 224, 231);
    position: relative;
    margin-left: -24px;
    margin-bottom: -24px;
    bottom: 0;
    z-index: 1030;
}

.mat-toolbar-multiple-rows-dddw {
    width: calc(100% + 48px) !important;
}

.mat-elevation-z8 {
    box-shadow: none;
}

.mat-table {
    margin-bottom: 45px;
}

.mat-cell {
    text-align: left;
    font-size: 12px;
    display: grid;
    font-size: 12px;
    margin-left: 20px;
}

.mat-header-row {
    margin-left: -17px;
}

.mr-sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: white;
}

.t-filtro {
    top: 24px !important;
    z-index: 800;
}

.t-table {
    top: 90px !important;
    z-index: 900;
}

.t-tb-sticky {
    position: sticky;
    top: 0px !important;
}

.row-cod {
    font-size: 16px;
    font-weight: bold;
}

.mat-cell:first-of-type {
    padding-left: 0;
}

.mat-header-cell:first-of-type {
    padding-left: 0 !important;
    padding-left: 18px !important;
}

mat-row:hover {
    background: lightblue !important;
    cursor: pointer;
}

mat-radio-group>mat-radio-button>label>.mat-radio-container {
    width: 15px;
    height: 15px;
}

mat-radio-group>mat-radio-button>label>.mat-radio-container>.mat-radio-outer-circle {
    width: 15px;
    height: 15px;
}

mat-radio-group>mat-radio-button>label>.mat-radio-container>.mat-radio-inner-circle {
    width: 15px;
    height: 15px;
}

.mat-paginator {
    background: none;
}

.mat-header-cell {
    align-content: center;
}

.mat-header-cell:first-of-type {
    padding-left: 0;
}

.mat-sort-header-container {
    margin: auto;
}

mat-toolbar>mat-toolbar-row>mat-icon {
    cursor: pointer;
}

.mat-table-scroll {
    width: 100%;
    overflow: auto;
    margin-bottom: 10px;
}

.mat-table-sc {
    width: 100%;
    overflow: auto;
    margin-top: 5px;
    max-height: calc(100vh - 210px) !important;
    position: relative;
}

.mat-table-scn {
    width: 100%;
    overflow: auto;
    margin-top: -10px;
    max-height: calc(100vh - 185px) !important;
    position: relative;
}

.mat-table-tb {
    margin-bottom: 5px !important;
}

.divyscroll.scrollbar-primary.card.m-1.pb-3.px-3.panel-modulos.ng-tns-c0-0 {
    margin: .52rem !important;
    margin-left: .80rem !important;
}

.mat-row {
    min-height: 21px;
    border-bottom-color: white;
}

.mat-row:hover {
    background: #87CEFA !important;
    cursor: pointer;
}

.mat-row:hover>.mat-cell {
    color: black;
}

.mat-row-select {
    background: rgb(87, 160, 233);
}

.mat-row-select>.mat-cell {
    color: white;
}

.table-row-venci {
    background-color: #C1C5D9;
}

.table-row-pago-invalid {
    background-color: rgb(255, 64, 64);
}

.table-row-bla {
    background-color: white;
}


/* Dialog Title */

.btn-close-card {
    background: none;
    border: none;
}

.img-close-card {
    height: 29px;
    margin-bottom: 5px;
}

button:focus {
    outline: none !important;
}

.mat-button-tab {
    background-color: #535c6b;
    color: #f0f3f7;
    margin-left: 1px !important;
    margin-right: 1px !important;
}

.mat-button-tab:disabled {
    background-color: #a6a9af;
    color: #d7dce4;
}


/* Dialog */

.mat-card {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0) !important;
    padding-top: 0 !important;
}

.mat-card-scroll {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.mat-card-header {
    text-align: center !important;
    display: block !important;
    margin-bottom: 10px;
    background-color: #354052;
    color: white;
    width: calc(100% + 80px);
    padding-top: 12px;
    margin-left: -40px;
    margin-bottom: 10px;
    margin-top: -24px !important;
    height: 50px;
}

.mat-card-title {
    font-size: 18px !important;
    padding-top: 5px;
}

.text-dddw {
    font-size: 20px;
    background-color: #354052;
    color: white;
    text-align: center;
    padding-top: 0.8%;
    width: calc(100% + 48px);
    height: 44px;
    margin-left: -24px;
    margin-top: -24px;
    position: relative;
}

.btn {
    background-color: #354052;
    border: none;
    color: white;
    /* padding: 12px 16px; */
    padding-top: 4px;
    font-size: 12px;
    cursor: pointer;
}

.card-content-mw {
    min-width: 450px;
}

.link{
    background-color: rgba(0,94,130, 0.925);
    color: white !important;
}

.link:hover{
    background-color: #87CEFA;
}

.dialog-flex {
    max-height: calc(100vh - 280px) !important;
}

@media(max-width: 670px) {
    .card-content-mw {
        min-width: auto;
    }
}


/* Darker background on mouse-over */

.btn:hover {
    background-color: white;
    color: black;
}

.mat-button-ddw {
    background-color: #535c6b;
    color: #f0f3f7;
    margin-left: 1px !important;
    margin-right: 1px !important;
}

.mat-button-cons {
    background-color: #0082BF;
}

.mat-button-proc {
    background-color: #0a1529;
}

.mat-form-field {
    display: inline !important;
}

.mat-autocomplete-panel>.mat-option {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
}

.mat-autocomplete-panel>.mat-option:hover {
    background-color: rgba(0, 0, 0, .12);
}

.mat-select-panel .mat-option {
    height: 32px !important;
}

.mat-card>.mat-card-actions {
    text-align: center;
}

.mat-card>.mat-card-actions>.mat-button {
    background-color: #535c6b;
    color: #f0f3f7;
    margin-left: 1px !important;
    margin-right: 1px !important;
}

.mat-card>.mat-card-actions>.mat-button:disabled {
    background-color: #a6a9af;
    color: #c7ccd3;
}

.dangerSnackBar {
    background-color: red;
    color: white;
}

.infoSnackBar {
    background-color: rgb(33, 150, 243);
    color: white;
}

.successSnackBar {
    background-color: green;
    color: white;
}

.confirmSnackBar {
    background-color: rgb(11, 145, 78);
    color: white;
}


/* Scroll Dialogo */

#style_menu_dialogo::-webkit-scrollbar {
    width: 8px;
    height: 40px;
}

#style_menu_dialogo::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(209, 212, 212, 0.945);
    scroll-snap-type: mandatory;
    overflow: auto;
    box-shadow: none;
}

#style_menu_dialogo::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(128, 128, 128, 0.11);
    border-radius: 10px;
    margin-top: 7px;
    margin-right: 5px !important;
}


/* Alertas */

mat-bottom-sheet-container {
    padding: 0 !important;
}

.nodis:disabled {
    opacity: 1 !important;
    color: #354052 !important;
}

.videoscroll{
    max-height: calc( 108vh - 250px);
}

.divyscroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc( 108vh - 200px);
}

.mat-table-tb {
    max-height: calc(100vh - 160px)!important;
}

.scrollbar-primary::-webkit-scrollbar {
    width: 7px;
    background-color: #fafafa;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.1);
    background-color: #354052;
}

.disabled-class {
    background-color: rgb(185, 185, 185);
    color: rgb(104, 104, 104);
}

.box-center {
    margin-left: 40%;
    margin-top: 15%;
}

.no-paddding-toolbar {
    padding: 0 16px;
}

.noOverflow {
    overflow: hidden !important;
    padding: 0 0 30px 0 !important;
}

.azul {
    background: #0052CC !important;
}
.azul:disabled {
    background: #6d95d1 !important;
}


/* Responsive */

.mat-tb {
    width: 100%;
    overflow: auto;
    position: relative;
}

.scn {
    margin-top: 0;
    max-height: calc(100vh - 95px) !important;
}

.scn-in {
    min-height: calc(100vh - 107px) !important;
}

.scf {
    margin-top: -14px;
    max-height: calc(100vh - 175px) !important;
}

.scf-in {
    min-height: calc(100vh - 200px) !important;
}

.scb {
    margin-top: 5px;
    max-height: calc(100vh - 210px) !important;
}

.scn-dg {
    margin-top: 0;
    margin-bottom: 5px;
    max-height: calc(100vh - 230px) !important;
}

.min-h {
    min-height: calc(100vh - 188px) !important;
}

.mat-elevation {
    box-shadow: none;
    margin-bottom: 5px !important;
}

.sticky {
    position: sticky;
    top: 0px !important;
    z-index: 1000;
    background: white;
}

.paginacion {
    width: 50px;
    font-size: 15px;
    margin-top: 5px;
    margin-left: 10px;
}

.ds {
    display: initial !important;
}

.ds-b {
    display: block !important;
}

.ds-t {
    margin-top: 32px;
    display: none !important;
}

.ds-m {
    display: none !important;
}

.ds-n {
    display: none !important;
}

.mat-h {
    padding-top: 4px;
    height: 34px;
}

.mat-t {
    padding-top: 0 !important;
    font-size: 17px !important;
    font-weight: 400;
}

.mat-tr {
    height: 46px !important;
}

.mat-ts {
    height: 46px !important;
    padding-top: 10px !important;
    margin-bottom: -15px;
}

.mat-pg {
    position: absolute;
    color: white;
    font-size: 19px;
    right: 15px;
}

.title-tab {
    font-size: 18px;
    background-color: #354052;
    color: white;
    text-align: center;
    padding-top: 13px;
    width: calc(100% + 48px);
    height: 46px;
    margin-left: -24px;
    margin-top: -24px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.title-tab-white {
  font-size: 18px;
  background-color: white;
  color: white;
  text-align: center;
  padding-top: 13px;
  width: calc(100% + 48px);
  height: 46px;
  margin-left: -24px;
  margin-top: -24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.card-dialog {
    padding: auto;
}

.card-m {
    margin-top: 20px;
    margin-bottom: 0 !important;
}

.card-s {
    margin-top: 10px;
    margin-bottom: 0 !important;
}

.btn-icon {
    margin-left: 10px;
    margin-right: 10px;
}

.btn-m {
    font-size: 30px;
    margin-top: -5px;
    color: white;
}

.ml-pag {
    margin-left: auto;
}

.mat-tab-body.mat-tab-body-active {
    overflow-y: hidden !important;
}

#style_menu_desplegable::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

#style_menu_desplegable::-webkit-scrollbar-thumb {
    border-radius: 75px;
    background-color: rgba(209, 212, 212, 0.945);
    scroll-snap-type: mandatory;
    overflow: auto;
    box-shadow: none;
}

.closeBtn {
    position: absolute;
    right: 13px;
    top: 12px;
}

.mat-toolbar-resp {
    background-color: rgba(220, 224, 231);
    position: fixed;
    margin-left: -20px;
    bottom: 0;
    z-index: 1030;
    min-height: 46px !important;
    width: 101% !important;
}

@media (max-width: 400px) {
    .ds-n {
        display: initial !important;
    }
    .mat-toolbar-resp {
        background-color: #354052;
    }
    .mat-paginator {
        color: auto;
    }
    .mat-paginator-icon {
        color: white !important;
        width: 36px !important;
    }
}

@media (max-width: 550px) {
    .mat-paginator-range-actions {
        display: flex !important;
    }
    .ds {
        display: none !important;
    }
    .ds-m {
        display: block !important;
    }
    .title-tab {
        font-size: 16px;
    }
    .card-dialog {
        padding-right: 8px !important;
    }
    .mat-button {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .mat-dialog-container {
        overflow-x: hidden !important;
    }
    .ml-pag {
        margin-left: -15px;
    }
}

@media (max-width: 850px) {
    .scn {
        max-height: calc(100vh - 130px) !important;
        margin-top: -10px;
    }
    .ds-t {
        display: block !important;
    }
    .ds-b {
        display: none !important;
    }
    .scb {
        max-height: calc(100vh - 185px) !important;
    }
}
.contentCuttonCobranza button{
    display: block;
    width: 100%;
}
.label_subetiqueta {
    background-color: rgb(40, 98, 224);
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    text-align: center;
    font-size: 15px;
    border-radius: 10px;
}
